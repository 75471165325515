import React, { useEffect } from "react";
import "./projectscommon.css";
import LazyImage from "../../../LazyImage";

import Overview from "../../../../assets/CURA/Overview.png";
import Solution from "../../../../assets/CURA/Solution.png";
import UserResearch from "../../../../assets/CURA/UserResearch.png";
import ExpertEvaluation from "../../../../assets/CURA/ExpertEvaluation.png";
import DesignGuidelines from "../../../../assets/CURA/DesignGuidelines.png";
import Prototype from "../../../../assets/CURA/Prototype.png";
const Tabs = [
  "Overview",
  "Solution",
  "User Research",
  "Design Guidelines",
  "Expert Evaluation",
  "Prototype",
];

const CURA = ({ setProjectTabs }) => {
  useEffect(() => {
    setProjectTabs(Tabs);

    return () => setProjectTabs([]);
  }, [setProjectTabs]);

  return (
    <div className="project-body">
      <div id="Overview">
        <LazyImage src={Overview} alt="Overview" loading="lazy" />
      </div>
      <div id="Solution">
        <LazyImage src={Solution} alt="Solution" loading="lazy" />
      </div>
      <div id="User Research">
        <LazyImage src={UserResearch} alt="User Research" loading="lazy" />
      </div>
      <div id="Design Guidelines">
        <LazyImage
          src={DesignGuidelines}
          alt="Design Guidelines"
          loading="lazy"
        />
      </div>

      <div id="Expert Evaluation">
        <LazyImage
          src={ExpertEvaluation}
          alt="Expert Evaluation"
          loading="lazy"
        />
      </div>
      <div id="Prototype" style={{ backgroundColor: "#252525" }}>
        <LazyImage src={Prototype} alt="Prototype" loading="lazy" />
        <iframe
          title="CURA Prototype"
          style={{
            width: "100%",
            height: "600px",
            border: "none",
            filter: "none",
            padding: "3%",
          }}
          loading="lazy"
          src="https://embed.figma.com/proto/WqIsuKYwSeR2eituoNCxJK/MHealth-(Copy)?page-id=1347%3A5952&node-id=1347-5961&p=f&viewport=788%2C693%2C0.5&scaling=scale-down&content-scaling=fixed&starting-point-node-id=1347%3A5956&embed-host=share&hide-ui=1"
        ></iframe>
      </div>
    </div>
  );
};

export default CURA;
